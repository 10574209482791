import { Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../../../../context/auth-context';
import { EventDto } from '../../../../../../types/dto/event.dto';

interface EventDetailsProps {
  event: EventDto;
  additionalInfo: boolean;
}

const EventDetails = ({ event, additionalInfo }: EventDetailsProps) => {
  const { t } = useTranslation(['events']);
  const { language } = useContext(AuthContext);

  return (
    <Descriptions
      column={1}
      bordered
      size='small'
      title={event?.subjectName}
      className='mt-1'
      labelStyle={{ width: '50%' }}
    >
      <Descriptions.Item label={t('event_list.date_and_time')}>
        {dayjs(event?.dateStart).format('DD.MM.YYYY. HH:mm')} -{' '}
        {dayjs(event?.dateEnd).format('HH:mm')}
      </Descriptions.Item>
      {event.subjects?.length ? (
        <Descriptions.Item label={t('create_update_event.form.subject')}>
          {event.subjects?.map((subject) =>
            language === 'en'
              ? subject.subjectNameEn ?? subject.subjectName
              : subject.subjectName
          )}
        </Descriptions.Item>
      ) : (
        <></>
      )}
      {event.lecturers?.length ? (
        <Descriptions.Item label={t('event_list.lecturers')}>
          {event.lecturers?.map((lecturer) => (
            <span key={lecturer.pin}>
              {lecturer.givenName + ' ' + lecturer.familyName}
            </span>
          ))}
        </Descriptions.Item>
      ) : (
        <></>
      )}
      {event.classroom && additionalInfo ? (
        <Descriptions.Item label={t('event_list.classroom')}>
          {event.classroom}
        </Descriptions.Item>
      ) : (
        <></>
      )}
      {event.location ? (
        <Descriptions.Item label={t('create_update_event.form.location.title')}>
          {t(`create_update_event.form.location.${event.location}`)}
        </Descriptions.Item>
      ) : (
        <></>
      )}
    </Descriptions>
  );
};

export default EventDetails;
