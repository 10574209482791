import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Select,
  Table,
  TableProps,
  Flex,
  Popconfirm,
  Button,
  notification,
  Tag,
  Tooltip,
  Empty,
  InputNumber,
} from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import {
  WorksheetDto,
  WorksheetEventDto,
  WorksheetEventUpdateDto,
} from '../../../../../../../types/dto/worksheet.dto';
import { EventType } from '../../../../../../../types/enum/event-type';
import { WorkSheetStatus } from '../../../../../../../types/enum/worksheet-status';

interface WorksheetEventsProps {
  worksheet: WorksheetDto;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
}

const WorksheetEvents = (props: WorksheetEventsProps) => {
  const { worksheet } = props;
  const { t } = useTranslation('worksheets');
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [events, setEvents] = useState<WorksheetEventDto[]>([]);

  const [editingEventId, setEditingEventId] = useState<string>();
  const [editData, setEditData] = useState<WorksheetEventUpdateDto>({
    duration: 0,
    eventType: EventType.LECTURE,
  });

  const checkIfAbleToSubmit = useCallback((events: WorksheetEventDto[]) => {
    const unfinishedEvent = events.find(
      (event) =>
        event.dateStart === null ||
        event.dateEnd === null ||
        event.eventType === null
    );

    if (unfinishedEvent) {
      props.setSubmitDisabled(true);
    } else {
      props.setSubmitDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWorksheetEvents = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getWorksheetEvents({
      page: 1,
      pageSize: 10,
      worksheetId: worksheet.id,
    });
    setEvents(response.records);
    checkIfAbleToSubmit(response.records);

    if (response.totalCount > 100) {
      const addOn = await Server.Worksheets.getWorksheetEvents({
        page: 2,
        pageSize: 100,
        worksheetId: worksheet.id,
      });

      setEvents((prevEvents) => [...prevEvents, ...addOn.records]);
      checkIfAbleToSubmit(addOn.records);
    }
    setLoading(false);
  }, [checkIfAbleToSubmit, worksheet.id]);

  useEffect(() => {
    fetchWorksheetEvents();
    setReload(false);
  }, [fetchWorksheetEvents, reload]);

  const updateEvent = async (wsEventId: string) => {
    setLoading(true);

    if (editData) {
      if (Number.isInteger(editData.duration)) {
        editData.duration = Number(editData.duration.toFixed(0));
      }

      const response = await Server.Worksheets.updateWorksheetEvent(
        wsEventId,
        editData
      );
      if (response.status === 200) {
        notification.success({
          message: t('edit.events.options.success_update'),
        });
        setEditingEventId('');
        setReload(true);
      }
    }

    setLoading(false);
    checkIfAbleToSubmit(events);
  };

  const deleteEvent = async (wsEventId: string) => {
    setLoading(true);

    const response = await Server.Worksheets.deleteWorksheetEvent(wsEventId);
    if (response.status === 200) {
      notification.success({
        message: t('edit.events.options.success_delete'),
      });
      setReload(true);
    }

    setLoading(false);
  };

  const eventsColumns: TableProps<WorksheetEventDto>['columns'] = [
    {
      key: 'subjectName',
      title: t('edit.events.subject_name'),
      dataIndex: 'event',
      width: '15%',
      render: (event) => <span>{event.subjectName}</span>,
    },
    {
      key: 'date',
      title: t('edit.events.duration'),
      dataIndex: 'event',
      width: '15%',
      render: (event, record) => {
        return record.dateStart && record.dateEnd ? (
          <Flex vertical>
            <span>{`${dayjs(record.dateStart).format('DD.MM.YYYY.')}`}</span>
            <span className='text-gray'>{`${dayjs(record.dateStart).format(
              'HH:mm'
            )} - ${dayjs(record.dateEnd).format('HH:mm')}`}</span>
          </Flex>
        ) : (
          '---'
        );
      },
    },
    {
      key: 'hours',
      title: t('edit.events.hours'),
      dataIndex: 'duration',
      width: '15%',
      render: (value, record) => {
        if (editingEventId === record.id) {
          return (
            <InputNumber
              decimalSeparator=','
              addonAfter={'h'}
              min={0}
              step={0.5}
              onChange={(value) => {
                if (value) {
                  setEditData((prevData) => ({ ...prevData, duration: value }));
                }
              }}
            />
          );
        }

        return record.duration ? <span>{value}</span> : '---';
      },
    },
    {
      key: 'eventType',
      title: t('edit.events.event_type.title'),
      dataIndex: 'eventType',
      width: '15%',
      render: (text, record) => {
        if (editingEventId === record.id) {
          return (
            <Select
              options={Object.values(EventType).map((type) => {
                return {
                  key: type,
                  value: type,
                  label: t(`edit.events.event_type.${type}`),
                };
              })}
              defaultValue={EventType.LECTURE}
              onChange={(value) =>
                setEditData((prevData) => ({ ...prevData, eventType: value }))
              }
            />
          );
        }

        return (
          <span>
            {text ? <Tag>{t(`edit.events.event_type.${text}`)}</Tag> : '---'}
          </span>
        );
      },
    },
    {
      key: 'attendance',
      title: t('edit.events.attendance'),
      dataIndex: 'attendance',
      width: '5%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'totalAmount',
      title: t('edit.events.total_amount'),
      dataIndex: 'totalAmount',
      width: '10%',
      render: (value) => (
        <span>{`${value ? value.toFixed(2).replace('.', ',') : 0} €`}</span>
      ),
    },
  ];

  if (worksheet.status === WorkSheetStatus.DRAFT) {
    eventsColumns.push({
      key: 'options',
      title: t('edit.events.options.title'),
      dataIndex: 'eventId',
      width: '30%',
      render: (id, record) => (
        <Flex gap={12}>
          {editingEventId === record.id ? (
            <>
              <Tooltip title={t('edit.expenses.actions.popconfirm.cancel')}>
                <Button
                  onClick={() => {
                    setEditingEventId('');
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={t('edit.expenses.actions.popconfirm.ok')}>
                <Button
                  loading={loading}
                  onClick={() => updateEvent(editingEventId)}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
            </>
          ) : (
            <Tooltip title={t('edit.events.options.edit_event')}>
              <Button
                onClick={() => {
                  setEditingEventId(record.id);
                  setEditData((prevData) => ({
                    ...prevData,
                    dateStart: record.event.dateStart,
                    dateEnd: record.event.dateEnd,
                  }));
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          )}
          <Popconfirm
            title={t('edit.events.options.popconfirm.title')}
            onConfirm={() => deleteEvent(record.id)}
            okText={t('edit.events.options.popconfirm.ok')}
            cancelText={t('edit.events.options.popconfirm.cancel')}
          >
            <Button loading={loading} danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      ),
    });
  }

  return (
    <>
      <h4>{t('edit.events.title')}</h4>
      <Table
        loading={loading}
        dataSource={events}
        scroll={{
          x: 800,
        }}
        columns={eventsColumns}
        pagination={{
          total: events.length,
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </>
  );
};

export default WorksheetEvents;
