import { QuestionCircleFilled } from '@ant-design/icons';
import { Col, Card, Flex, Tooltip, Switch, Button, notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../api/server-index';
import { AuthContext } from '../../../../../../context/auth-context';

const ChangeEmailSubscriptionStatus = () => {
  const { t } = useTranslation('settings');
  const { user, refreshUser } = useContext(AuthContext);
  const [subscriptionStatus, setSubscriptionStatus] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      setSubscriptionStatus(user?.subscribedToEmails);
    }
  }, [user]);

  const handleSubscriptionChange = async () => {
    const response = await Server.User.changeSubscriptionStatus(
      subscriptionStatus
    );
    if (response.status === 200) {
      notification.success({ message: t('email_sub.change.success') });
      refreshUser();
    }
  };

  return (
    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
      <Card>
        <Flex justify='space-between' align='center'>
          <Flex gap={8}>
            <h4>{t('email_sub.change.title')}</h4>
            <Tooltip title={t('email_sub.change.tooltip')}>
              <QuestionCircleFilled />
            </Tooltip>
          </Flex>
          <Switch
            value={subscriptionStatus}
            onChange={(value) => setSubscriptionStatus(value)}
          />
        </Flex>
        <Flex justify='end'>
          {' '}
          <Button
            onClick={() => handleSubscriptionChange()}
            type='primary'
            ghost
          >
            {t('email_sub.change.confirm')}
          </Button>
        </Flex>
      </Card>
    </Col>
  );
};

export default ChangeEmailSubscriptionStatus;
