import { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    borderRadius: 10,
    colorPrimary: '#4066a0',
    colorInfo: '#6498e7',
    colorError: '#ff4d4f',
  },
  components: {
    Button: {
      colorTextDisabled: 'gray',
    },
    Menu: {
      itemBg: 'rgb(255, 255, 255)',
      horizontalItemHoverColor: 'rgb(255, 255, 255)',
    },
    Layout: {
      bodyBg: 'rgb(248, 248, 250)',
    },
    Table: {
      headerColor: 'rgba(255, 255, 255, 0.88)',
      headerBg: '#304a70',
      headerSortActiveBg: '#001F4E',
      headerSplitColor: '#001F4E',
      borderColor: '#d1d8e2',
    },
    Tooltip: {
      paddingXS: 12,
      paddingSM: 12,
      colorBgSpotlight: '#36527c',
    },
    DatePicker: {
      lineWidth: 1,
      cellWidth: 30,
      cellHeight: 22,
    },
    Descriptions: {
      labelBg: 'rgba(48,74,112, 0.05)',
      colorSplit: 'rgba(48,74,112, 0.1)',
    },
    Badge: {
      colorError: '#6498e7',
      indicatorHeightSM: 12,
      textFontSizeSM: 10,
    },
  },
};
