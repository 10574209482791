import { Button, Card, Descriptions, Flex, Spin } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import { EventDto } from '../../../../../types/dto/event.dto';

const CheckIn = () => {
  const { logout } = useContext(AuthContext);
  const [params] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [checkedIn, setCheckedIn] = useState<boolean>(false);
  const [event, setEvent] = useState<EventDto>();
  const eventId = params.get('eventId');
  const { t } = useTranslation('check-in');

  useEffect(() => {
    const fetchEvent = async () => {
      if (eventId) {
        setLoading(true);

        const response = await Server.EventCheckIn.checkIn(eventId);
        if (response.status === 201) {
          setCheckedIn(true);
          const eventRes = await Server.Events.getEventById(eventId);
          setEvent(eventRes);
        }

        setLoading(false);
      }

      setLoading(false);
    };

    fetchEvent();
  }, [eventId]);

  return (
    <Spin spinning={loading}>
      <Flex justify='center' align='center' style={{ height: '60vh' }}>
        <Card className='check-in-card'>
          {eventId && checkedIn ? (
            <>
              <div className='text-center mb-2'>
                <img
                  src={'/assets/aspira-uciliste-w.png'}
                  alt='Logo'
                  className='img-fluid mw-100'
                />
              </div>
              <h2 className='text-center'>{t('success')}</h2>
              <Descriptions bordered column={1} className='check-in-table'>
                <Descriptions.Item label={t('subjectName')}>
                  {event?.subjectName}
                </Descriptions.Item>
                <Descriptions.Item label={t('date')}>
                  <Flex gap={4}>
                    <span>{`${dayjs(event?.dateStart).format(
                      'DD.MM.YYYY.'
                    )}`}</span>
                    <span className='text-gray'>{`${dayjs(
                      event?.dateStart
                    ).format('HH:mm')} - ${dayjs(event?.dateEnd).format(
                      'HH:mm'
                    )}`}</span>
                  </Flex>
                </Descriptions.Item>
                {event?.classroom ? (
                  <Descriptions.Item label={t('classroom')}>
                    {event?.classroom}
                  </Descriptions.Item>
                ) : (
                  <></>
                )}
                {event?.notes ? (
                  <Descriptions.Item label={t('notes')}>
                    {event?.notes}
                  </Descriptions.Item>
                ) : (
                  <></>
                )}
              </Descriptions>
              <Flex justify='center'>
                <Button onClick={logout} className='logout-button mt-1'>
                  {t('logout')}
                </Button>
              </Flex>
            </>
          ) : (
            <Flex vertical>
              <div className='text-center mb-2'>
                <img
                  src={'/assets/aspira-uciliste-w.png'}
                  alt='Logo'
                  className='img-fluid mw-100'
                />
              </div>
              <h2 className='text-center'>{t('error_title')}</h2>
              <p className='m-0'>{t('error_desc')}</p>
              <ul>
                <li>{t('timeframe')}</li>
                <li>{t('attendance_list')}</li>
                <li>{t('valid_link')}</li>
              </ul>
              <Flex justify='center'>
                <Button onClick={logout} className='logout-button mt-1'>
                  {t('logout')}
                </Button>
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </Spin>
  );
};

export default CheckIn;
