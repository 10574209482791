import {
  Button,
  DatePicker,
  Empty,
  Flex,
  Spin,
  Table,
  TableProps,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../../../api/server-index';
import { SearchDataSelection } from '../../../../../../../types/common/search-data-selection.component';
import { PaginatedData } from '../../../../../../../types/dto/common.dto';
import { MonthlyWorkLogDto } from '../../../../../../../types/dto/work-logs.dto';
import { setNavigationPath } from '../../../../../../../utils/navigation-params';
import FilterIcon from '../../../../../../common/icons/filter-icon.component';
import NewMonthlyWorkLog from '../new-mothly-work-log/new-work-log.component';
const { MonthPicker } = DatePicker;

interface Props {
  setMonthlyWorkLogId: Dispatch<SetStateAction<string>>;
}

const MonthlyWorkLogsList = (props: Props) => {
  const { t } = useTranslation('work-logs');
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [monthlyWorkLogs, setMonthlyWorkLogs] =
    useState<PaginatedData<MonthlyWorkLogDto[]>>();
  const [filter, setFilter] = useState<{ month?: number; year?: number }>({
    month: searchParams.get('month')
      ? Number(searchParams.get('month'))
      : undefined,
    year: searchParams.get('year')
      ? Number(searchParams.get('year'))
      : undefined,
  });

  const fetchMonthlyWorkLogs = useCallback(async () => {
    setLoading(true);
    const response = await Server.WorkLogs.getMonthlyWorkLogs({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
      month: filter.month,
      year: filter.year,
    });

    setMonthlyWorkLogs(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchMonthlyWorkLogs();
    setSearchParams(setNavigationPath(filter, dataSelection));
    setReload(false);
  }, [dataSelection, fetchMonthlyWorkLogs, filter, reload, setSearchParams]);

  const handleMonthChange = (date: any) => {
    if (date) {
      const selectedMonth = date.month() + 1;
      const selectedYear = date.year();
      setFilter({
        month: selectedMonth,
        year: selectedYear,
      });
      setDataSelection((prevVal) => ({
        ...prevVal,
        page: 1,
      }));
    } else {
      setFilter({
        month: undefined,
        year: undefined,
      });
      setDataSelection((prevVal) => ({
        ...prevVal,
        page: 1,
      }));
    }
  };

  const columns: TableProps<MonthlyWorkLogDto>['columns'] = [
    {
      key: 'period',
      title: t('month_and_year'),
      dataIndex: 'id',
      width: '50%',
      filterDropdown: () => (
        <MonthPicker
          className='w-100'
          placeholder={t('month_and_year')}
          format={'M/YYYY'}
          value={
            filter.month !== undefined && filter.year !== undefined
              ? dayjs(`${filter.year}-${filter.month}`, 'YYYY-M')
              : null
          }
          onChange={handleMonthChange}
        />
      ),
      filterIcon: <FilterIcon count={filter.month ? 1 : 0} />,
      render: (id, record) => (
        <Tooltip title={t('view.title')}>
          <Button
            style={{ padding: 0 }}
            type='link'
            onClick={() => props.setMonthlyWorkLogId(id)}
          >{`${record.month}/${record.year}`}</Button>
        </Tooltip>
      ),
    },
    {
      key: 'createdAt',
      title: t('createdAt'),
      dataIndex: 'createdDate',
      width: '50%',
      render: (createdDate) => (
        <Flex gap={6}>
          <span>{dayjs(createdDate).format('DD.MM.YYYY.')}</span>
          <span className='text-gray'>
            {dayjs(createdDate).format('HH:mm')}
          </span>
        </Flex>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <NewMonthlyWorkLog setReload={setReload} />
      <Table
        rowKey={(val) => val.id}
        className='mt-1'
        dataSource={monthlyWorkLogs?.records}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={{
          total: monthlyWorkLogs?.totalCount,
          hideOnSinglePage: true,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export default MonthlyWorkLogsList;
