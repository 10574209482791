import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import MainTitle from '../../../../common/titles/main-title.component';
import ChangeEmailSubscriptionStatus from './change-email-subscription-status/change-email-subscription-status.component';
import ChangePassword from './change-password/change-password.component';
import ChangeSecurityPin from './change-security-pin/change-security-pin.component';

const Settings = () => {
  const { t } = useTranslation('settings');

  return (
    <div>
      <MainTitle text={t('title')} />
      <ChangeSecurityPin />
      <Row
        className='mt-1 mb-2'
        gutter={[{ xl: 16, lg: 16, md: 0, sm: 0, xs: 0 }, 16]}
      >
        <ChangePassword />
        <ChangeEmailSubscriptionStatus />
      </Row>
    </div>
  );
};

export default Settings;
