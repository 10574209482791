import { Col, Descriptions, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { MonthlyWorkLogDto } from '../../../../../../../../types/dto/work-logs.dto';

interface Props {
  monthlyWorkLog: MonthlyWorkLogDto;
}

const MonthlyWorkLogSummary = ({ monthlyWorkLog }: Props) => {
  const { t } = useTranslation('work-logs');

  const totalHours = [
    monthlyWorkLog.totalDailyWorkHours ?? 0,
    monthlyWorkLog.totalNightWorkHours ?? 0,
    monthlyWorkLog.totalAnnualLeaveHours ?? 0,
    monthlyWorkLog.totalMedicalLeaveHours ?? 0,
    monthlyWorkLog.totalHolidayHours ?? 0,
    monthlyWorkLog.totalOvertimeHours ?? 0,
  ].reduce((sum, hours) => sum + hours, 0);

  return (
    <Row className='w-100'>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Descriptions
          column={1}
          bordered
          size='small'
          title={t('view.summary.title')}
          className='mt-1 mb-3'
          labelStyle={{ width: '50%' }}
        >
          <Descriptions.Item label={t('view.summary.totalDaily')}>
            {monthlyWorkLog.totalDailyWorkHours}
          </Descriptions.Item>
          <Descriptions.Item label={t('view.summary.totalNight')}>
            {monthlyWorkLog.totalNightWorkHours}
          </Descriptions.Item>
          <Descriptions.Item label={t('view.summary.totalAnnual')}>
            {monthlyWorkLog.totalAnnualLeaveHours}
          </Descriptions.Item>
          <Descriptions.Item label={t('view.summary.totalMedical')}>
            {monthlyWorkLog.totalMedicalLeaveHours}
          </Descriptions.Item>
          <Descriptions.Item label={t('view.summary.totalHoliday')}>
            {monthlyWorkLog.totalHolidayHours}
          </Descriptions.Item>
          <Descriptions.Item label={t('view.summary.totalOvertime')}>
            {monthlyWorkLog.totalOvertimeHours}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('view.summary.total')}
            contentStyle={{ fontWeight: 'bold' }}
            labelStyle={{ fontWeight: 'bold' }}
          >
            {totalHours}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default MonthlyWorkLogSummary;
