import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const MonthlyWorkLogPDFTitle = () => {
  const { t } = useTranslation('work-logs');

  const styles = StyleSheet.create({
    wrapper: {
      fontFamily: 'Roboto',
    },
    title: {
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: 4,
    },
  });

  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>{t('view.title')}</Text>
    </View>
  );
};

export default MonthlyWorkLogPDFTitle;
