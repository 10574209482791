import {
  CalendarFilled,
  FolderFilled,
  IdcardFilled,
  ProfileFilled,
  QuestionCircleFilled,
  ReadFilled,
  SettingFilled,
  UserOutlined,
} from '@ant-design/icons';

import Activate from '../components/layout/pages/activate.component';
import CheckInList from '../components/layout/pages/lecturer/events/check-in-list/check-in-list.component';
import EditEvent from '../components/layout/pages/lecturer/events/edit-event/edit-event.component';
import EventsList from '../components/layout/pages/lecturer/events/events-list/events-list.component';
import Events from '../components/layout/pages/lecturer/events/events.component';
import NewEvent from '../components/layout/pages/lecturer/events/new-event/new-event.component';
import EditPartner from '../components/layout/pages/lecturer/partner/edit-partner/edit-partner.component';
import NewPartner from '../components/layout/pages/lecturer/partner/new-partner/new-partner.component';
import PartnerInfo from '../components/layout/pages/lecturer/partner/partner-info/partner-info.component';
import Partner from '../components/layout/pages/lecturer/partner/partner.component';
import Settings from '../components/layout/pages/lecturer/settings/settings.component';
import SubjectList from '../components/layout/pages/lecturer/subjects/subject-list/subject-list.component';
import SubjectStudents from '../components/layout/pages/lecturer/subjects/subject-list/subject-students/subject-students.component';
import Subjects from '../components/layout/pages/lecturer/subjects/subjects.component';
import UserManual from '../components/layout/pages/lecturer/user-manual/user-manual.component';
import WorkLogsAndRequests from '../components/layout/pages/lecturer/work-logs-and-requests/work-logs-and-requests.component';
import EditWorksheet from '../components/layout/pages/lecturer/worksheets/edit-worksheet/edit-worksheet.component';
import WorksheetsList from '../components/layout/pages/lecturer/worksheets/worksheets-list/worksheets-list.component';
import Worksheets from '../components/layout/pages/lecturer/worksheets/worksheets.component';
import CheckIn from '../components/layout/pages/student/check-in/check-in.component';
import { UserDto } from '../types/dto/user.dto';
import { UserRoles } from '../types/enum/user-roles';
import { HasRoles } from '../utils/has-roles';

export const RoutesConstants = (user?: UserDto) => {
  return [
    {
      path: '/events',
      element: <Events />,
      icon: <CalendarFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user),
      sidebar: true,
      name: 'events',
      subroutes: [
        {
          path: '',
          element: <EventsList />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'new',
          element: <NewEvent />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'edit/:eventId',
          element: <EditEvent />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'checked-in/:eventId',
          element: <CheckInList />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
      ],
    },
    {
      path: '/worksheets',
      element: <Worksheets />,
      icon: <FolderFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user),
      sidebar: true,
      name: 'worksheets',
      subroutes: [
        {
          path: '',
          element: <WorksheetsList />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'edit/:worksheetId',
          element: <EditWorksheet />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
      ],
    },
    {
      path: '/subjects',
      element: <Subjects />,
      icon: <ReadFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user),
      sidebar: true,
      name: 'subjects',
      subroutes: [
        {
          path: '',
          element: <SubjectList />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'students/:subjectId',
          element: <SubjectStudents />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
      ],
    },
    {
      path: '/work-logs-requests',
      element: <WorkLogsAndRequests />,
      icon: <ProfileFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user) && user?.isEmployee,
      sidebar: true,
      name: 'work-logs-requests',
    },
    {
      path: '/partner',
      element: <Partner />,
      icon: <IdcardFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user),
      sidebar: true,
      name: 'partner',
      subroutes: [
        {
          path: '',
          element: <PartnerInfo />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'new',
          element: <NewPartner />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
        {
          path: 'edit/:partnerPin',
          element: <EditPartner />,
          visibleToUser: HasRoles([UserRoles.LECTURER], user),
        },
      ],
    },
    {
      path: '/check-in',
      element: <CheckIn />,
      icon: <UserOutlined />,
      visibleToUser: HasRoles([UserRoles.LECTURER, UserRoles.STUDENT], user),
      sidebar: false,
      name: 'check-in',
    },
    {
      path: '/settings',
      element: <Settings />,
      icon: <SettingFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user),
      sidebar: true,
      name: 'settings',
    },
    {
      path: '/change-password',
      element: <Activate />,
      icon: <></>,
      visibleToUser: HasRoles([UserRoles.LECTURER, UserRoles.STUDENT], user),
      sidebar: false,
      name: 'change-password',
    },
    {
      path: '/manual',
      element: <UserManual />,
      icon: <QuestionCircleFilled />,
      visibleToUser: HasRoles([UserRoles.LECTURER], user),
      sidebar: true,
      name: 'user-manual',
    },
  ];
};
