import { View, StyleSheet, Text } from '@react-pdf/renderer';
const MonthlyWorkLogPDFFooter = () => {
  const styles = StyleSheet.create({
    footer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 40,
      borderTop: '1px solid #ccc',
      paddingTop: '10px',
      textAlign: 'center',
      fontSize: 8,
    },
  });

  return (
    <View style={styles.footer} fixed>
      <Text>
        Domovinskog rata 65 • 21000 Split • Tel: 021/382-802 • Fax: 021/382-805
      </Text>
      <Text>
        OIB: 14885934105 • IBAN: HR4024840081104992880 • E-mail: info@aspira.hr
        • www.aspira.hr
      </Text>
    </View>
  );
};
export default MonthlyWorkLogPDFFooter;
