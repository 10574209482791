import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  Flex,
  Spin,
  Table,
  TableProps,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { EventCheckInDto } from '../../../../../../types/dto/event-check-in.dto';
import { EventDto } from '../../../../../../types/dto/event.dto';
import BackButton from '../../../../../common/buttons/back-button.component';
import Subtitle from '../../../../../common/titles/subtitle.component';
import EventDetails from '../event-details/event-details.component';

const CheckInList = () => {
  const { t } = useTranslation(['events', 'common']);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [checkIns, setCheckIns] = useState<EventCheckInDto[]>([]);
  const [event, setEvent] = useState<EventDto>(location.state);

  const fetchEvent = useCallback(async () => {
    const eventId = params.eventId;
    if (eventId) {
      const eventResponse = await Server.Events.getEventById(eventId);
      setEvent(eventResponse);
    }
  }, [params.eventId]);

  const fetchCheckIns = useCallback(async () => {
    setLoading(true);
    if (event) {
      const response = await Server.EventCheckIn.getCheckIns(event.id);
      setCheckIns(response);
    } else {
      notification.error({ message: t('check_ins.fetch_error') });
      navigate(-1);
    }

    setLoading(false);
  }, [event, navigate, t]);

  useEffect(() => {
    if (!event) {
      fetchEvent();
    }
    fetchCheckIns();
  }, [event, fetchCheckIns, fetchEvent]);

  const downloadCSV = () => {
    try {
      const csvData = checkIns.map((item) => [
        item.user?.pin || '',
        item.user?.hrEduUid || '',
        item.user?.givenName || '',
        item.user?.familyName || '',
        item.user?.email || '',
        'P',
      ]);

      const csvContent =
        `${t('check_ins.csv.pin')},${t('check_ins.csv.hrEduUid')},${t(
          'check_ins.csv.givenName'
        )},${t('check_ins.csv.familyName')},${t('check_ins.csv.email')},${t(
          'check_ins.csv.status'
        )}\n` + csvData.map((row) => row.join(',')).join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${event?.subjectName}_${event?.id}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      notification.error({ message: t('check_ins.csv_error') });
    }
  };

  const columns: TableProps<EventCheckInDto>['columns'] = [
    {
      key: 'time',
      title: t('check_ins.time'),
      dataIndex: 'timeStamp',
      render: (timeStamp) => {
        const time = dayjs(timeStamp).format('DD.MM.YYYY. HH:mm');
        return <span>{time}</span>;
      },
    },
    {
      key: 'user',
      title: t('check_ins.student'),
      dataIndex: 'user',
      render: (user) => <span>{user.givenName + ' ' + user.familyName}</span>,
    },
  ];

  return (
    <Spin spinning={loading}>
      <BackButton href={`/events${location?.search}`} />
      <EventDetails event={event} additionalInfo={true} />
      <Flex align='center' justify='space-between' className='mt-2'>
        <Subtitle text={t('check_ins.title')} />
        <Button onClick={() => downloadCSV()}>
          <DownloadOutlined />
          {t('check_ins.csv.download')}
        </Button>
      </Flex>
      <Table
        className='mt-1'
        dataSource={checkIns}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
        scroll={{ x: 800 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export default CheckInList;
