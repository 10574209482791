import {
  Document,
  Page,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import {
  DailyWorkLogDto,
  MonthlyWorkLogDto,
} from '../../../../../../../../types/dto/work-logs.dto';
import { ColumnType } from '../../../../../../../../types/enum/wl-column-type';
import MonthlyWorkLogPDFFooter from './footer/mwl-footer';
import MonthlyWorkLogPDFInfo from './info/mwl-info';
import MonthlyWorkLogPDFRecap from './recap/mwl-recap';
import MonthlyWorkLogPDFTable from './table/mwl-table';
import MonthlyWorkLogPDFTitle from './title/mwl-title';

interface Props {
  monthlyWorkLog: MonthlyWorkLogDto;
  dailyWorkLogs: DailyWorkLogDto[];
}

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 600,
    },
  ],
});

const MonthlyWorkLogPdf = ({ monthlyWorkLog, dailyWorkLogs }: Props) => {
  const styles = StyleSheet.create({
    logoRowCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '20px 0',
    },
    logo: { height: '20px', maxWidth: '100px' },
    footer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 40,
      borderTop: '1px solid #ccc',
      paddingTop: '10px',
      textAlign: 'center',
      fontSize: 8,
    },
  });

  return (
    <Document
      title={`WL_${monthlyWorkLog?.month}/${monthlyWorkLog?.year}_${monthlyWorkLog?.employeePin}`}
    >
      {/* title, info, presence */}
      <Page size='A4' wrap orientation='landscape'>
        <View style={styles.logoRowCenter}>
          <Image source={'/assets/aspira-uciliste.png'} style={styles.logo} />
        </View>
        <MonthlyWorkLogPDFTitle />
        <MonthlyWorkLogPDFInfo monthlyWorkLog={monthlyWorkLog} />
        <MonthlyWorkLogPDFTable
          columnType={ColumnType.PRESENCE}
          monthlyWorkLog={monthlyWorkLog}
          dailyWorkLogs={dailyWorkLogs}
        />
      </Page>

      {/* absence */}
      <Page size='A4' wrap orientation='landscape'>
        <MonthlyWorkLogPDFInfo monthlyWorkLog={monthlyWorkLog} />
        <MonthlyWorkLogPDFTable
          columnType={ColumnType.ABSENCE}
          monthlyWorkLog={monthlyWorkLog}
          dailyWorkLogs={dailyWorkLogs}
        />
      </Page>

      {/* recap */}
      <Page size='A4' wrap orientation='landscape'>
        <MonthlyWorkLogPDFInfo monthlyWorkLog={monthlyWorkLog} />
        <MonthlyWorkLogPDFRecap monthlyWorkLog={monthlyWorkLog} />
        <MonthlyWorkLogPDFFooter />
      </Page>
    </Document>
  );
};

export default MonthlyWorkLogPdf;
