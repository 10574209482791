import { decode } from 'base-64';
import dayjs from 'dayjs';
import React from 'react';

import { Server } from '../api/server-index';
import { CAS_SERVER_URL, API_URL } from '../constants/constants';
import { Credentials } from '../types/auth';
import { UserDto } from '../types/dto/user.dto';
import { LoginType } from '../types/enum/login-type';
import { UserRoles } from '../types/enum/user-roles';

interface AuthState {
  isAuthenticated: boolean;
  language: string;
  currentAcademicYear: number;
  intendedDestination?: string;
  user?: UserDto;
  securityPin?: string;
}

interface AuthActions {
  login: (credentials: Credentials) => Promise<void>;
  loginWithAaiEdu: (intendedDestination?: string) => void;
  tokenLogin: (authToken: string) => Promise<void>;
  logout: () => Promise<void>;
  checkAuth: (force?: boolean) => Promise<void>;
  setIntendedDestination: (intendedDestination?: string) => void;
  checkSecurityPin: (pin: string) => boolean;
  refreshUser: () => Promise<void>;
  changeLanguage: (language: string) => void;
}

/**
 * This is a TypeScript workaround to solve having to define dummy functions.
 * Create partial context where all properties are optional and cast it to non partial context that gets properly populated in AuthProvider
 */
const AuthContextPartial: React.Context<Partial<AuthState & AuthActions>> =
  React.createContext({});

export const AuthContext = AuthContextPartial as React.Context<
  AuthState & AuthActions
>;
//*********************************/

export function AuthProvider(props: any) {
  const currentAcademicYear =
    dayjs().get('month') >= 9 ? dayjs().get('year') : dayjs().get('year') - 1;
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState<UserDto>();
  const [securityPin, setSecurityPin] = React.useState<string>();
  const [intendedDestination, _setIntendedDestination] =
    React.useState<string>();
  const [language, setLanguage] = React.useState<string>(
    window.localStorage.getItem('language') ?? 'hr'
  );

  const _fetchUser = async (): Promise<void> => {
    try {
      const apiUser = await Server.User.fetchUser();
      if (apiUser) {
        setIsAuthenticated(true);
        setUser({
          ...apiUser,
        });
      }

      if (apiUser.roles?.some((role) => role === UserRoles.LECTURER)) {
        const pin = await Server.User.getSecurityPin();
        if (pin) {
          setSecurityPin(pin);
        }
      }
    } catch {}
  };

  const stateMemo = React.useMemo(
    () => ({
      isAuthenticated,
      intendedDestination,
      user,
      securityPin,
      language,
      currentAcademicYear,

      login: async (credentials: Credentials) => {
        if (await Server.Auth.login(credentials)) {
          await _fetchUser();
        }
        window.localStorage.setItem('loginType', LoginType.SEPI);
      },

      loginWithAaiEdu: (intendedDestination?: string) => {
        const encodedUrl = `${API_URL}/api/auth/cas-ticket?client=SEPI${
          intendedDestination
            ? encodeURIComponent(`&intendedDestination=${intendedDestination}`)
            : ''
        }`;
        const casLoginUrl = `${CAS_SERVER_URL}/login?service=${encodedUrl}`;
        window.localStorage.setItem('loginType', LoginType.CAS);
        window.location.href = casLoginUrl;
      },

      tokenLogin: async (authToken: string) => {
        window.localStorage.setItem('authToken', authToken);
        await _fetchUser();
      },

      logout: async () => {
        if (window.localStorage.getItem('loginType') === LoginType.CAS) {
          Server.Auth.logoutWithAaiEdu();
        } else {
          Server.Auth.logout();
        }

        setIsAuthenticated(false);
        setUser(undefined);
      },

      checkAuth: async (force = false): Promise<void> => {
        if ((!isAuthenticated && localStorage.getItem('authToken')) || force) {
          await _fetchUser();
        }
      },

      setIntendedDestination(intendedDestination?: string) {
        if (intendedDestination) {
          //Lets return thise stripped `=` chars from b64, decode it and store
          const divDiff = 4 - (intendedDestination.length % 4);
          intendedDestination += '='.repeat(divDiff !== 4 ? divDiff : 0);
          intendedDestination = decode(intendedDestination);
        }

        _setIntendedDestination(intendedDestination);
      },

      checkSecurityPin(pin: string) {
        if (securityPin?.toString() === pin) {
          return true;
        } else {
          return false;
        }
      },

      refreshUser: async () => {
        await _fetchUser();
      },

      changeLanguage(language: string) {
        window.localStorage.setItem('language', language);
        setLanguage(language);
      },
    }),
    [
      isAuthenticated,
      intendedDestination,
      user,
      securityPin,
      language,
      currentAcademicYear,
    ]
  );

  return (
    <AuthContext.Provider value={stateMemo}>
      {props.children}
    </AuthContext.Provider>
  );
}
